body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

html {
  height: 100%;
  font-size: 13px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






section {
  display: flex;
  flex-flow: column;
  height: 100%;
}

header {
  background: #ffffff;
  /* no flex rules, it will grow */
  border-bottom: solid 1px #dbdad8;
}


.logo {
  margin: 10px;
/*  image-rendering: high-quality;
  transform: translateZ(0);*/
}



.content {
  flex: 1;  /* 1 and it will fill whole space left if no flex value are set to other children*/

  overflow: hidden;


  display: flex;
  flex-flow: column;


}

footer {

  background: #ffffff;

  border-top: solid 1px #dbdad8;


}


.ui-widget {
  font-family: 'Segoe UI', Arial, sans-serif;
  font-size: 1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}

root {
  font-size: 12px;
  font-family: 'Segoe UI', Arial, sans-serif;
}


.st0 {
  fill: #D14BE2;
}


Button {

}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
