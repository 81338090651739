.icon:hover {
    fill: red;
}


.journeyBlock:hover {
    fill: #8C8C8C;
    transition: fill 300ms;
}


.journeyBlock {
    fill: #b8b7b8;
}



.journeyToolTip {
    background: #000000;
    color: #ffffff;
    display: inline;
    position: absolute;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
}


.journeyToolTip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}


.p-inputtext {
    padding: 5px !important;
}
