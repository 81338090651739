.p-scrollpanel-wrapper {
    border-right: 9px solid #ffffff;
}

.p-scrollpanel-bar {
    background-color: #cad4f8 !important;
    opacity: 1;
   transition: background-color .3s;
}

.p-scrollpanel-bar:hover {
    background-color: #735b89 !important;
}


.p-scrollpanel-content {
    padding: 0 0 18px 0  !important;
}


h4 {
    margin-left: 20px;
}

.vehicleButton:hover {
    background: #e0dfe2;
    transition: background-color 500ms;
}


.activeChips {
    background: rgba(115, 24, 236, 0.15) !important;
    border-radius: 4px !important;
    padding: 0px 5px 0px 5px !important;
    margin: 0px 5px 0px 5px !important;

}
