.container {
    display: flex;
    height: 100%;


}

.container__sidebar {
    width: 300px;
    min-width: 300px;
    /*background: #f8eddd;*/
    background: #f8f9fa;
    /*overflow: hidden;*/
    height: 100%;
    margin-left: 0px;

}

.container__main {
    flex: 1;
    display: flex;
    flex-direction: column;



}

.aboveMapContainer {
    position: absolute;
    z-index: 100;
    width: 100%;

}



.p-accordion-content {
    background: rgba(255, 255, 255, 0.60) !important;
}


.p-accordion .p-accordion-header .p-accordion-header-link {
    background: rgba(255, 255, 255, 0.60) !important;
}



.journey {
    background: rgba(255, 255, 255, 0.8);
}




.mapContainer {
    display: flex;
    height: 100vh;
    background: #d4eade;
    position: relative;


}


.mapboxgl-ctrl-icon {
    background-image: url("#../assets/triangle.svg")  !important;
   /* background: #7dd5f8 !important;*/
}

.mapboxgl-ctrl-zoom-in:before {
    content: "+";
    font-weight: bold;
    font-size: 14px;
}


.mapboxgl-ctrl-zoom-out:before {
    content: "-";
    font-weight: bold;
    font-size: 16px;
}

.mapboxgl-ctrl-traffic:before {
    content: "t";
    font-weight: bold;
    font-size: 14px;
}

.mapboxgl-ctrl-satellite:before {
    content: "s";
    font-weight: bold;
    font-size: 14px;
}


.marker {
    /*background: rgba(255, 255, 255, 0.4);*/
    width: 140px;
    height: 140px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px;
    backface-visibility: hidden;
}


.startMarker {
    /*background: rgba(255, 255, 255, 0.4);*/
    width: 140px;
    height: 60px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px;
    backface-visibility: hidden;
}





.sideBarButton {
    background-color: #F0F1F2;
    border: #cecdcd solid 1px;
    border-left: none;
    border-top: none;
    padding: 5px 10px 5px 10px;
    float: right;

    color: #686767;
    font-weight: normal;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    font-size: 20px;
}


.sideBarButton:hover {
    background-color: #b4b2b2;
}

/*.clearfix {
    clear: both;
    height: 0;
    background: #7dd5f8;
    line-height: 0;
}*/
